<template>
  <div :class="`admin-menu m${id}`" :scrollable="isScrollable" @click="isScrollBarVisible">
    <div class="admin-menu-content" v-if="$user && $profile">
      <div class="admin-menu-user" v-if="$user">
        <div class="admin-menu-user-avatar tcenter __menu">
          <UserAvatar :user="Go.extend($user, $profile.user)" size="60px" @click="goToUserFeed($user)" cursor="pointer" />
          <div class="admin-menu-user-name">{{ $user.name }}</div>
          <div class="admin-menu-user-role" tcenter>
            <small><MyRoleLabel /></small>
          </div>
        </div>
      </div>
      <div class="admin-menu-options" v-if="$screen.width < 1040">
        <router-link class="primary-circle userProfileButton" :to="`${$isCreator ? `/${$user.user}` : $links['my-profile']}`">
          <iconic name="user" />
        </router-link>
        <a class="primary-circle" @click="UserAdminMenu"><iconic name="bars" /></a>
      </div>
      <div class="admin-menu-nav" v-else>
        <AdminMenuOptions />
      </div>
    </div>
    <FeedMenu />
  </div>
</template>

<script>
export default {
  components: {
    AdminMenuOptions: () => import("../admin/AdminMenuOptions.vue"),
    FeedMenu: () => import("../feed/FeedMenu.vue"),
  },
  data: function() {
    return {
      id: Go.uuid(),
      isScrollable: false,
    };
  },
  methods: {
    isScrollBarVisible: async function() {
      await Go.sleep(APP_TRANSITION_TIME * 0.7);
      this.isScrollable = Go.isScrollBarVisible(`.m${this.id}`);
    },
  },
  mounted: function() {
    this.isScrollBarVisible();
  },
};
</script>
